<template>
  <div
    id="binding-mcn-page"
    class="binding-mcn-page"
  >
    <van-loading
      class="loading"
      type="spinner"
      color="#1989fa"
      size="24"
      v-if="!session"
    />
    <section-text :text="session ? (session.type==='external_user'? '当前客户':'当前客户群'):'当前客户/群'" />
    <div class="contact">
      <img
        class="cover"
        src="@/assets/images/icon-client@2x.png"
        v-if="!session || session.type==='external_user'"
      >
      <img
        class="cover"
        src="@/assets/images/icon-group@2x.png"
        v-else
      >
      <span class="name">{{ session ? session.name : '-' }}</span>
    </div>
    <section-text text="绑定MCN机构" />
    <select-mcn ref="select_mcn" />
    <div
      class="bottom-placeholder"
      v-if="hasPermission"
    />
    <div
      class="bottom"
      :style="'width:'+bottomWidth+'px;'"
      v-if="hasPermission"
    >
      <van-button
        class="btn-back"
        @click="back"
      >
        返回上一级
      </van-button>
      <van-button
        class="btn-bound"
        @click="bound"
      >
        绑定
      </van-button>
    </div>


    <van-action-sheet
      v-model:show="showActionSheet"
      title="确定绑定"
    >
      <div class="content">
        <div class="session-info">
          <span class="title">{{ session.type === 'external_user' ? '客户:' : '客户群:' }}</span>
          <span class="brief">{{ session.name }}</span>
        </div>
        <div class="mcn-info">
          <span class="title">机构名称:</span>
          <span class="brief">{{ getSelectedMcnName() }}</span>
        </div>
        <div class="text">
          <span>确定将该{{ session.type === 'external_user' ? '客户' : '客户群' }}绑定至该机构名下吗？</span>
        </div>
      </div>
      <div
        class="actions"
        :style="'width:'+bottomWidth+'px;'"
      >
        <van-button
          class="btn-cancel"
          @click="cancel"
        >
          取消
        </van-button>
        <van-button
          class="btn-confirm"
          :loading="bounding"
          @click="toBound"
        >
          确定
        </van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import SectionText from '@/components/SectionText';
import SelectMcn from './SelectMcn';
import { mapActions, mapState } from 'vuex';
import { Toast } from 'vant';

export default {
  name: 'BingingMcn',
  components: {
    SectionText,
    SelectMcn
  },
  data() {
    return {
      sessionId: this.$route.params.id,
      hasPermission: true,
      bottomWidth: 0,
      showActionSheet: false,
      bounding: false
    };
  },
  computed: {
    ...mapState({
      configured: (state) => state.wecom.configured,
      session: (state) => state.session.session,
    })
  },
  created() {
    this.toGetWeComSessionDetail();
    this.$nextTick(function () {
      const page_el = document.getElementById('binding-mcn-page');
      if (page_el) {
        this.bottomWidth = page_el.offsetWidth;
      }
    });
  },
  methods: {
    back() {
      this.$router.back();
    },
    bound() {
      if (!this.$refs.select_mcn.selectedMcn) {
        Toast.fail('请先选择机构');
        return;
      }
      this.showActionSheet = true;
    },
    cancel() {
      this.showActionSheet = false;
    },
    async toBound() {
      this.bounding = true;
      const res = await this.bindTiktokMcn(
        { id: this.session.id, mcnId: this.$refs.select_mcn.selectedMcn.id }
      ).catch((rej) => {
        this.bounding = false;
      });
      if(res){
        Toast.success('绑定成功');
        this.showActionSheet = false;
      }
      this.bounding = false;
      this.$router.back();
    },
    getSelectedMcnName() {
      if (!this.$refs.select_mcn.selectedMcn || !this.$refs.select_mcn.selectedMcn.name) {
        return '-';
      }
      return this.$refs.select_mcn.selectedMcn.name;
    },
    async toGetWeComSessionDetail() {
      if (!this.sessionId) {
        Toast.fail({
          message: '参数异常',
        });
        return;
      }
      await this.getWeComSessionDetail({
        id: this.sessionId,
      }).catch(() => {
      });
    },
    ...mapActions({
      getWeComSessionDetail:
        'session/getWeComSessionDetail',
      bindTiktokMcn:
        'session/bindMcn',
    }),
  }
};
</script>

<style lang="less" scoped>
.binding-mcn-page {
  position: relative;
  box-sizing: border-box;
  background: #fff;

  .contact {
    display: flex;
    align-items: center;
    padding-left: 40px;

    .cover {
      width: 64px;
      height: 64px;
    }

    .name {
      margin-left: 20px;
      font-size: 30px;
      font-weight: 400;
      color: #333;
      line-height: 30px;
    }
  }


  .bottom {
    display: flex;
    align-items: center;
    background: #fff;
    height: 160px;
    border-top: 1px solid #E6E6E6;
    position: fixed;
    bottom: 0;

    .btn-back {
      width: 40%;
      background: #FFFFFF;
      border-radius: 40px;
      border: 1px solid #0095FF;
      font-size: 30px;
      font-weight: 600;
      color: #0095FF;
      line-height: 30px;
      margin-left: 30px;

      &:active {
        opacity: 0.8;
      }
    }

    .btn-bound {
      width: 60%;
      background: #0095FF;
      border-radius: 40px;
      font-size: 30px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 10px;
      margin-right: 30px;

      &:active {
        opacity: 0.8;
      }
    }
  }

  .bottom-placeholder {
    height: 160px;
  }
}

.loading {
  position: absolute;
  left: calc(50% - 12px);
  top: 300px;
  z-index: 9999;
}

.content {
  padding: 40px;

  .title {
    display: inline-block;
    width: 160px;
    font-size: 32px;
    font-weight: 400;
    color: #333;
    line-height: 60px;
  }

  .brief {
    font-size: 32px;
    font-weight: 600;
    color: #333;
    line-height: 60px;
  }

  .text {
    font-size: 32px;
    font-weight: 400;
    color: #333;
    line-height: 44px;
    margin-top: 50px;
  }
}


.actions {
  display: flex;
  align-items: center;
  background: #fff;
  height: 160px;
  border-top: 1px solid #E6E6E6;

  .btn-cancel {
    width: 30%;
    background: #FFFFFF;
    border-radius: 40px;
    border: 1px solid #0095FF;
    font-size: 30px;
    font-weight: 600;
    color: #0095FF;
    line-height: 30px;
    margin-left: 30px;

    &:active {
      opacity: 0.8;
    }
  }

  .btn-confirm {
    width: 70%;
    background: #0095FF;
    border-radius: 40px;
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 30px;
    margin-left: 10px;
    margin-right: 30px;

    &:active {
      opacity: 0.8;
    }
  }
}
</style>