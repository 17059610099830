<template>
  <div :class="selected? 'cell-mcn-select':''">
    <div
      class="cell-mcn"
      @click="toMcnPage"
    >
      <van-radio-group
        v-model="selected"
        @click.stop="select"
        @change="handleChange"
      >
        <van-radio name="1">
          &nbsp;
        </van-radio>
      </van-radio-group>
      <img
        class="avatar"
        src="@/assets/images/icon-mcn@2x.png"
      >
      <div class="middle">
        <span class="title">{{ data && data.name ? data.name : '-' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSelectCellMcn',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      selected: false
    };
  },
  watch: {
    editCheck(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.checked = false;
      }
    }
  },
  methods: {
    select() {

    },
    handleChange() {
      if (!this.selected) {
        return;
      }
      this.$emit('selectChange', this.data.id);
    },
    toMcnPage() {
      if (this.editCheck) {
        return;
      }
      console.log('to mcn page');
    }
  },
};
</script>

<style lang="less" scoped>
.cell-mcn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  margin: 0 40px;
  border-bottom: 1px solid #E0E0E0;

  .checkbox {
    margin-left: 30px;
    margin-right: -30px;
  }

  .avatar {
    flex-shrink: 0;
    width: 108px;
    height: 108px;
    border-radius: 60px;
    margin-left: 40px;
  }

  .middle {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .title {
      font-size: 32px;
      font-weight: 600;
      color: #333;
      line-height: 32px;
      margin-left: 30px;
      overflow: hidden;
    }
  }
}

.cell-mcn-select {
  background: #EFF8FF;
}
</style>