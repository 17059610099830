<template>
  <div class="select-mcn">
    <div class="search">
      <van-field
        class="search-input"
        v-model="value"
        placeholder="请输入机构名称"
        @update:model-value="inputChange"
      />
      <van-button
        class="search-button"
        type="primary"
        :disabled="searchDisabled"
        @click="toQueryMcn(keyword)"
      >
        搜索
      </van-button>
    </div>
    <div
      class="mcn-list"
      v-if="mcns && mcns.length > 0"
    >
      <single-select-cell-mcn
        v-for="mcn in mcns"
        :ref="'mcn_'+mcn.id"
        :data="mcn"
        :key="mcn.id"
        @selectChange="handleMcnSelectChange"
      />
    </div>
    <van-loading
      class="loading"
      type="spinner"
      color="#1989fa"
      size="24"
      v-else-if="searching"
    />
    <empty
      v-else
    />
  </div>
</template>

<script>
import Empty from '@/components/Empty';
import SingleSelectCellMcn from './components/SingleSelectCellMcn';
import { mapActions } from 'vuex';

export default {
  name: 'SelectMcn',
  components: {
    Empty,
    SingleSelectCellMcn,
  },
  data() {
    return {
      searching: false,
      keyword: undefined,
      value: '',
      searchDisabled: true,
      mcns: [],
      selectedMcn: undefined,
    };
  },
  watch: {
    mcns(newValue, oldValue) {
      if (!this.selectedMcn) {
        return;
      }
      let selectedMcnExists = false;
      this.mcns.forEach((x) => {
        if (x.id === this.selectedMcn.id) {
          selectedMcnExists = true;
        }
      });
      if (!selectedMcnExists) {
        this.selectedMcn = undefined;
      }
    },
  },
  methods: {
    inputChange(val) {
      this.searchDisabled = !(val && val.length > 0);
      this.keyword = val;
      if (val && val.length > 0) {
        this.toQueryMcn(val);
      } else {
        this.mcns = [];
      }
    },
    handleMcnSelectChange(id) {
      this.mcns.forEach((x) => {
        if (x.id === id) {
          this.selectedMcn = x;
        } else {
          this.$refs['mcn_' + x.id][0].selected = false;
        }
      });
    },
    async toQueryMcn(name) {
      this.searching = true;
      try {
        const res = await this.queryMcn({ name });
        if (this.keyword !== name) {
          return;
        }
        this.mcns = res.data;
      } catch (err) {
      } finally {
        this.searching = false;
      }

    },
    ...mapActions({
      queryMcn:
        'star/queryMcn',
    }),
  }
};
</script>

<style lang="less" scoped>
.select-mcn {
  .search {
    position: relative;
    padding: 40px 30px;
    border-bottom: 1px solid #E0E0E0;

    .search-input {
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: #F7F7F7;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 65px;
      border: 1px solid #E6E6E6;
      //font-size: 30px;
    }

    .search-button {
      position: absolute;
      top: 40px;
      right: 30px;
      width: 140px;
      height: 80px;
      border-radius: 65px;
    }
  }
}
.loading {
  position: absolute;
  left: calc(50% - 12px);
  top: 600px;
  z-index: 9999;
}
</style>